import axios, { AxiosPromise } from 'axios';

import { IPricesProjection, IPricesProjectionPayload } from '@contexts/prices-projection';
import { IPaginatedResponse } from '@contexts/types';

import config from '../../config';

import { transformObjectKeysToSnakeCase } from '@utils/common';

export const calculatorApi = axios.create({
  baseURL: config.CALCULATOR_API_HOST,
});

export function createPricesProjection(formData: IPricesProjectionPayload, projectionId?: string): AxiosPromise {
  const preserveKeys = new Set(['i_5', 'i_100']);
  const snakedFormData = transformObjectKeysToSnakeCase(formData, preserveKeys);
  return calculatorApi.post(
    `/api/v1/partners-price-projection${projectionId ? `/${projectionId}` : ''}`,
    snakedFormData,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
      },
    },
  );
}

export function getPricesProjection(projectionId: string): AxiosPromise {
  return calculatorApi.get(`/api/v1/partners-price-projection/${projectionId}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
  });
}

export function getPartnerPricesProjection(
  retailTraderId: string,
  page?: number,
): AxiosPromise<IPaginatedResponse<IPricesProjection>> {
  return calculatorApi.get('/api/v1/partners-price-projection', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Apikey ${config.CALCULATOR_API_KEY}`,
    },
    params: {
      retail_trader_id: retailTraderId,
      page,
    },
  });
}
