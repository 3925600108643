import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import Layout from '@components/templates/main-layout';

import PricesProjectionsProvider from '@contexts/prices-projection';
import TradersProvider from '@contexts/trader';

import NotFound404 from '@pages/404';
import HomePage from '@pages/home';
import UpsertPricesProjectionPage from '@pages/upsert-prices-projection';
import DetailedPricesProjectionPage from '@pages/detailed-prices-projection';

import PrivateRoute from './private-route';
import { HOME_PATH, UPSERT_PRICES_PROJECTION_PATH, DETAILED_PRICES_PROJECTION_PATH } from './constants';

const MainRouter: React.FC = () => {
  const UpsertPricesProjectionPageWrapper = () => (
    <PrivateRoute>
      <PricesProjectionsProvider>
        <UpsertPricesProjectionPage />
      </PricesProjectionsProvider>
    </PrivateRoute>
  );

  return (
    <Routes>
      {/* ----------- Private routes ----------- */}
      <Route
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route
          path={HOME_PATH}
          element={
            <PrivateRoute>
              <TradersProvider>
                <PricesProjectionsProvider>
                  <HomePage />
                </PricesProjectionsProvider>
              </TradersProvider>
            </PrivateRoute>
          }
        />
        <Route
          element={
            <TradersProvider>
              <Outlet />
            </TradersProvider>
          }
        >
          <Route path={`${UPSERT_PRICES_PROJECTION_PATH}`} element={<UpsertPricesProjectionPageWrapper />} />
          <Route path={`${UPSERT_PRICES_PROJECTION_PATH}/:id`} element={<UpsertPricesProjectionPageWrapper />} />
        </Route>
        <Route
          path={`${DETAILED_PRICES_PROJECTION_PATH}/:id`}
          element={
            <PrivateRoute>
              <PricesProjectionsProvider>
                <TradersProvider>
                  <DetailedPricesProjectionPage />
                </TradersProvider>
              </PricesProjectionsProvider>
            </PrivateRoute>
          }
        />
        {/* ----------- Admin routes ----------- */}
      </Route>
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default MainRouter;
