import { useNavigate } from 'react-router-dom';

import { UPSERT_PRICES_PROJECTION_PATH } from '@routers/constants';

import { PriceProjectionsHistoryCore } from '@components/organisms/price-projections-history/core';
import Layout, { LayoutProps } from '@components/atoms/layout';
import { useAuth } from '@src/auth-wrapper';

export function PriceProjectionsHistoryPage() {
  const { user } = useAuth();
  const baseLinks = [
    {
      label: 'Histórico',
      url: '#',
    },
  ];
  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [...baseLinks];

  const navigate = useNavigate();
  const headerButtons: LayoutProps['headerButtons'] = [
    {
      kind: 'primary',
      label: 'Cadastrar novos preços',
      type: 'button',
      icon: 'PlusIcon',
      className: 'text-paragraph-medium',
      onClick: () => navigate(UPSERT_PRICES_PROJECTION_PATH),
    },
  ];

  return (
    <Layout
      title="Histórico de Preços Clarke Varejo"
      navigationHistoryLinks={navigationHistoryLinks}
      headerButtons={user?.isAdmin || user?.isSuperAdmin ? [] : headerButtons}
      darkerBackground
    >
      <div className="h-full col-span-full">
        <PriceProjectionsHistoryCore />
      </div>
    </Layout>
  );
}
