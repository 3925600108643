export function PriceProjectionsHistoryNoDataMessage({ isAdmin }: { isAdmin?: boolean }) {
  return (
    <div className="flex col-span-full justify-center items-center w-full min-h-full h-screen p-8">
      <span className="flex flex-col justify-start items-start w-full min-h-full">
        <h1 className="text-heading-medium font-bold">Nenhum registro encontrado</h1>
        {isAdmin && <p className="text-heading-xsmall">Por favor, selecione outro fornecedor.</p>}
      </span>
    </div>
  );
}

export function PriceProjectionsHistoryNoTraderSelectedMessage() {
  return (
    <div className="flex col-span-full justify-center items-center w-full min-h-full h-screen p-8">
      <span className="flex flex-col justify-start items-start w-full min-h-full">
        <h1 className="text-heading-medium font-bold">Você ainda não selecionou nenhum fornecedor</h1>
        <p className="text-heading-xsmall">Por favor, selecione um fornecedor acima para continuar.</p>
      </span>
    </div>
  );
}
