import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

import config from '@src/config';

import MainRouter from '@routers/main-router';

import ApolloWrapper from './apollo-wrapper';
import AuthWrapper from './auth-wrapper';

function App() {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.DOMAIN}
      clientId={config.CLIENT_ID}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      audience={`https://${config.DOMAIN}/api/v2/`}
      scope="read:current_user"
      onRedirectCallback={onRedirectCallback}
    >
      <AuthWrapper>
        <ApolloWrapper>
          <MainRouter />
        </ApolloWrapper>
      </AuthWrapper>
    </Auth0Provider>
  );
}

export default App;
