import { useContextSelector } from 'use-context-selector';

import { TraderContext } from '@contexts/trader';
import { TraderContextType } from '@contexts/trader/types';

const useTraders = (): TraderContextType => {
  const getTraders = useContextSelector(TraderContext, (traders) => traders.getTraders);
  const getTraderForUserHandler = useContextSelector(TraderContext, (traders) => traders.getTraderForUserHandler);
  const setTraderId = useContextSelector(TraderContext, (traders) => traders.setTraderId);
  const loading = useContextSelector(TraderContext, (traders) => traders.loading);
  const traders = useContextSelector(TraderContext, (traders) => traders.traders);
  const traderById = useContextSelector(TraderContext, (traderById) => traderById.traderById);

  return {
    getTraders,
    getTraderForUserHandler,
    setTraderId,
    traders,
    traderById,
    loading,
  };
};

export default useTraders;
