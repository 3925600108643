import Tag from '@components/atoms/tag';
import { IPricesProjection } from '@contexts/prices-projection';
import { formatNumeral } from '@utils/numberal';

export function PriceProjectionsHistoryTableTagColumn({ priceProjection }: { priceProjection: IPricesProjection }) {
  return (
    <td className="col-span-2 flex items-center text-start min-h-full">
      <Tag
        kind={'icon'}
        icon={'CurrencyDollarIcon'}
        label={formatNumeral(priceProjection.charges ?? 0, '$ 0,0.00').concat('/MWh')}
        color={'green'}
      />
    </td>
  );
}
