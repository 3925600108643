import React from 'react';
import { HeroIcon, IHeroIcon } from './hero-icon';
import { twMerge } from 'tailwind-merge';

type Variant = 'default' | 'icon' | 'remove';
export type TagColor = 'primary' | 'gray' | 'green' | 'red' | 'white' | 'yellow' | 'blue' | 'neutral';
type IconOptions = 'None' | IHeroIcon['icon'];
type TagSize = 'small' | 'medium';
export type TagType = 'upwarding' | 'downwarding' | 'neutral' | 'default';
interface ITagSizeProp {
  typography: string;
}
export interface TagProps extends React.ComponentProps<'div'> {
  /**
   * The tag variant
   */
  kind: Variant;
  /**
   * Tag label
   */
  label: string;
  /**
   * Tag id -> used to locate tag
   */
  id?: string;
  /**
   * Icon to be shown, if any
   */
  icon?: IconOptions;
  /**
   * Tag color
   */
  color: TagColor;
  /**
   * Remove action -> if used, render an "x" button at the right side of the tag
   *
   */
  onRemove?: (el: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Custom attributes (e.g. test selectors like `data-cy="test"`).
   * Be careful to not overlap it with other default attributes.
   * */
  customAttrs?: Record<string, string>;
  /**
   * The tag vertical size
   */
  size?: TagSize;
}

const DEFAULT_COLOR_GETTER: Record<TagColor, Record<'text' | 'background' | 'iconColor', string>> = {
  gray: {
    text: 'text-black',
    iconColor: 'text-black',
    background: 'bg-neutral-10',
  },
  green: {
    text: 'text-[#008C4C]',
    iconColor: 'text-[#00BF6A]',
    background: 'bg-[#E6F9DC]',
  },
  primary: {
    text: 'text-black',
    iconColor: 'text-[#00BF6A]',
    background: 'bg-primary-60',
  },
  yellow: {
    text: 'text-warning-80',
    iconColor: 'text-warning-80',
    background: 'bg-warning-10',
  },
  blue: {
    text: 'text-blue-80',
    iconColor: 'text-blue-80',
    background: 'bg-blue-10',
  },
  red: {
    text: 'text-danger-90',
    iconColor: 'text-danger-90',
    background: 'bg-danger-10',
  },
  white: {
    text: 'text-black',
    iconColor: 'text-black',
    background: 'bg-white',
  },
  neutral: {
    text: 'text-neutral-60',
    iconColor: 'text-neutral-60',
    background: 'bg-neutral-10',
  },
};

const TAG_SIZE_PROPS_GETTER: Record<TagSize, ITagSizeProp> = {
  small: {
    typography: 'text-paragraph-small',
  },
  medium: {
    typography: 'text-paragraph-medium',
  },
};

const Tag: React.FC<TagProps> = ({ kind, color, label, id, icon, onRemove, customAttrs, size = 'small', ...attrs }) => {
  const { className, ...restAttrs } = attrs;
  const bgClass = DEFAULT_COLOR_GETTER[color]['background'];
  const textClass = `text-paragraph-small ${DEFAULT_COLOR_GETTER[color]['text']}`;
  const borderClass = color === 'white' ? 'border border-black' : 'border-none';
  const typography = TAG_SIZE_PROPS_GETTER[size].typography;

  const dynamicClasses = [bgClass, textClass, borderClass, typography, className].join(' ');
  const iconProps = {
    className: twMerge('w-5 h-5', DEFAULT_COLOR_GETTER[color]['iconColor']),
    useSolid: true,
  };

  return (
    <div
      className={`flex flex-nowrap w-fit truncate gap-3 pl-3 pr-5 py-2 items-center rounded-pill ${dynamicClasses}`}
      {...customAttrs}
      {...restAttrs}
    >
      {kind === 'icon' && icon && icon !== 'None' && <HeroIcon icon={icon} {...iconProps} />}
      {label}
      {kind === 'remove' && onRemove && (
        <button value={id} onClick={(event) => onRemove(event)}>
          <HeroIcon icon="XMarkIcon" {...iconProps} />
        </button>
      )}
    </div>
  );
};

export default Tag;
