export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
export const formatDate = (date: Date) =>
  new Intl.DateTimeFormat('pt-BR', dateOptions).format(date).replace(/\.? de /g, ' ');

export const formatNumber = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options ? options : { style: 'decimal', useGrouping: true, maximumFractionDigits: 2 };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCurrency = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options ? options : { style: 'currency', currency: 'BRL', useGrouping: true };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCNPJ = (num: string): string => {
  const formatted: string[] = [];
  [...num].forEach((char, index) => {
    formatted.push(char);
    if (index === 1 || index == 4) formatted.push('.');
    if (index === 7) formatted.push('/');
    if (index === 11) formatted.push('-');
  });
  return formatted.join('');
};

export const formatCPF = (num: string): string => {
  const formatted: string[] = [];
  [...num].forEach((char, index) => {
    formatted.push(char);
    if (index === 2 || index == 5) formatted.push('.');
    if (index === 8) formatted.push('-');
  });
  return formatted.join('');
};
