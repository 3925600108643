import { PriceProjectionsHistoryTableAtoms as TableAtoms } from '@components/atoms/price-projections-history/table';
import { PricesProjectionContextType } from '@contexts/prices-projection';

interface IPriceProjectionsHistoryTableBody {
  pricesProjections: PricesProjectionContextType['pricesProjections'];
}
export function PriceProjectionsHistoryTableBody({ pricesProjections }: IPriceProjectionsHistoryTableBody) {
  return (
    <tbody>
      {pricesProjections &&
        pricesProjections.data?.map((priceProjection, index) => (
          <tr
            key={`price-projection-history-${index}`}
            className="grid grid-cols-10 items-center gap-4 w-full h-10 bg-white rounded-xl mt-5 px-5 text-paragraph-small"
          >
            <TableAtoms.ResponsibleColumn priceProjection={priceProjection} />
            <TableAtoms.TagColumn priceProjection={priceProjection} />
            <TableAtoms.DatesColumns priceProjection={priceProjection} />
            <TableAtoms.ButtonsColumn priceProjection={priceProjection} />
          </tr>
        ))}
    </tbody>
  );
}
