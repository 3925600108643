import { IPricesProjectionPayload } from '@contexts/prices-projection';
import moment from 'moment';
import { UseFormSetError } from 'react-hook-form';

export function validatePricesProjectionPayload(
  values: IPricesProjectionPayload,
  setError: UseFormSetError<IPricesProjectionPayload>,
  setLoading: (value: React.SetStateAction<boolean>) => void,
) {
  if (!values.charges) {
    setLoading(false);
    setError('charges', {
      type: 'required',
      message: 'Defina o valor dos encargos',
    });

    return false;
  }
  if (!values.expirationDate) {
    setLoading(false);
    setError('expirationDate', {
      type: 'required',
      message: 'Preencha a data de validade corretamente',
    });

    return false;
  } else if (moment(values.expirationDate).isBefore(moment())) {
    setLoading(false);
    setError('expirationDate', {
      type: 'required',
      message: 'A data de validade deve ser maior que a data atual',
    });

    return false;
  }
  return true;
}
