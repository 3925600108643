import React from 'react';

import style from './style.module.css';
import { CheckboxProps } from './root';

export interface CheckboxLabelProps extends CheckboxProps {
  required?: boolean;
  content: string;
}

export const CheckboxLabel: React.FC<CheckboxLabelProps> = ({
  id,
  content,
  required,
  className,
  disabled,
  size = 'small',
}) => {
  const labelSizeClassName = `text-paragraph-${size}`;
  const disabledClassName = disabled ? 'text-brand-gray-20' : 'text-brand-gray-80';
  const dynamicClassName = [labelSizeClassName, disabledClassName, className].join(' ');
  return (
    <div className={style.labelContainer}>
      <label className={dynamicClassName} htmlFor={id}>
        {content}
      </label>
      {required && <span className="text-red-600">*</span>}
    </div>
  );
};
