import { IPricesProjection } from '@contexts/prices-projection';

export function PriceProjectionsHistoryTableResponsibleColumn({
  priceProjection,
}: {
  priceProjection: IPricesProjection;
}) {
  return (
    <td className="col-span-3 items-center self-center flex flex-nowrap gap-4 w-full text-start h-full">
      {priceProjection.user.name ? (
        <>
          <img src={priceProjection.user.pictureUrl} className="w-7 h-7 rounded-full" alt="User Picture" />
          <label className="text-paragraph-small">{priceProjection.user.name}</label>
        </>
      ) : (
        <label>Usuário não identificado</label>
      )}
    </td>
  );
}
