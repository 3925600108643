import { gql } from '@apollo/client';

export const GET_TRADERS_QUERY = gql`
  query {
    traders {
      data {
        id
        name
        description
        createdAt
        traderType
        assets
        website
        companyFoundationYear
      }
    }
  }
`;

export const GET_TRADER_QUERY = gql`
  query Trader($traderId: ID!) {
    trader(id: $traderId) {
      id
      name
      traderType
      description
      companyFoundationYear
      website
      assets
      createdAt
    }
  }
`;

export const GET_TRADER_FOR_USER_QUERY = gql`
  query GetTraderForUser($userId: ID!) {
    getTraderForUser(userId: $userId) {
      trader {
        id
        name
      }
    }
  }
`;
