import React, { ReactNode } from 'react';

import style from './style.module.css';

export type CheckboxSize = 'small' | 'large';

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: CheckboxSize;
}

export interface CheckboxRootProps extends CheckboxProps {
  children: ReactNode;
  onCheckedChange?: (value: boolean) => void;
}

export const CheckboxRoot: React.FC<CheckboxRootProps> = ({ children, size = 'small', ...attrs }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<CheckboxProps>(child)) {
      return React.cloneElement(child, { size: size, ...attrs });
    }
    return child;
  });

  return <div className={style.root}>{childrenWithProps}</div>;
};
