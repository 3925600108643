import React from 'react';
import { Outlet } from 'react-router-dom';

import SideBar, { MobileSidebar } from '@components/molecules/general/sidebar';

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col h-screen max-h-screen md:flex-row">
      <div className="p-1 sm:p-3 md:hidden">
        <MobileSidebar />
      </div>
      <div className="hidden items-start place-items-start p-4 space-y-4 h-full md:flex md:flex-col md:justify-between md:shrink-0 w-fit">
        <SideBar />
      </div>
      <div className="flex-1 h-full overflow-clip">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
