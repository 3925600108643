import React from 'react';

import usePricesProjections from '@hooks/use-prices-projection';
import Skeleton from 'react-loading-skeleton';

import configuration from '@config';
import { useAuth } from '@src/auth-wrapper';
import useTraders from '@hooks/use-trader';

import { PriceProjectionsHistoryTable } from '@components/organisms/price-projections-history/table';
import {
  PriceProjectionsHistoryNoDataMessage,
  PriceProjectionsHistoryNoTraderSelectedMessage,
} from '@components/atoms/price-projections-history/messages';

export function PriceProjectionsHistoryCore() {
  const { pricesProjections, partnerId, setPartnerId } = usePricesProjections();
  const { loading: loadingTraders } = useTraders();
  const { user } = useAuth();

  React.useEffect(() => {
    setPartnerId(configuration.MAIN_PARTNER_ID);
  }, []);

  if (partnerId) {
    if (pricesProjections && pricesProjections?.total > 0) {
      return <PriceProjectionsHistoryTable />;
    } else return <PriceProjectionsHistoryNoDataMessage isAdmin={user?.isAdmin} />;
  } else if (user?.isAdmin) {
    if (!loadingTraders) return <PriceProjectionsHistoryNoTraderSelectedMessage />;
    else
      return (
        <div className="mt-8 w-full">
          <Skeleton className="w-full" count={4} enableAnimation />
        </div>
      );
  } else return <></>;
}
