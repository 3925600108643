import moment from 'moment';

import { HOME_PATH, UPSERT_PRICES_PROJECTION_PATH } from '@routers/constants';
import ButtonComponent from '@components/atoms/button';

interface IFooterProps {
  idOfPriceProjection?: string;
  expirationDate?: string;
}

export const Footer: React.FC<IFooterProps> = ({ idOfPriceProjection, expirationDate }: IFooterProps) => {
  const disabled = moment(expirationDate, 'YYYY/MM/DD hh:mm') <= moment();

  return (
    <>
      <ButtonComponent
        kind="primary"
        label="Editar"
        onClick={() => (location.href = `${UPSERT_PRICES_PROJECTION_PATH}/${idOfPriceProjection}`)}
        disabled={disabled}
        className={disabled ? 'text-neutral-20' : 'hover:bg-neutral-30'}
      />
      <ButtonComponent
        kind="secondary"
        label="Voltar"
        onClick={() => (location.href = HOME_PATH)}
        className="hover:bg-neutral-30"
      />
    </>
  );
};
