import moment from 'moment';

import { IPricesProjection } from '@contexts/prices-projection';

export function PriceProjectionsHistoryTableDatesColumns({ priceProjection }: { priceProjection: IPricesProjection }) {
  const rawDateFormat = 'YYYY-MM-DD HH:mm:ss';
  const parsedDateFormat = 'DD/MM/YYYY [às] HH:mm[h]';

  const expirationDate = moment(priceProjection.expirationDate, rawDateFormat);
  const creationDate = moment(priceProjection.createdAt, rawDateFormat);

  return (
    <>
      <td className="col-span-2 flex items-center text-start min-h-full">{creationDate.format(parsedDateFormat)}</td>
      <td className="col-span-2 flex items-center text-start min-h-full">{expirationDate.format(parsedDateFormat)}</td>
    </>
  );
}
