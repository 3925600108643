import { PriceProjectionsHistoryTableButtonsColumn } from './buttons-column';
import { PriceProjectionsHistoryTableDatesColumns } from './date-columns';
import { PriceProjectionsHistoryTableHead } from './head';
import { PriceProjectionsHistoryTableResponsibleColumn } from './responsible-column';
import { PriceProjectionsHistoryTableTagColumn } from './tag-column';

export const PriceProjectionsHistoryTableAtoms = {
  Head: PriceProjectionsHistoryTableHead,
  ButtonsColumn: PriceProjectionsHistoryTableButtonsColumn,
  ResponsibleColumn: PriceProjectionsHistoryTableResponsibleColumn,
  TagColumn: PriceProjectionsHistoryTableTagColumn,
  DatesColumns: PriceProjectionsHistoryTableDatesColumns,
};
