import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { ColumnOptions, Table } from '@clarke-energia/foton';

import { HOME_PATH } from '@routers/constants';

import usePricesProjections from '@hooks/use-prices-projection';
import useTraders from '@hooks/use-trader';

import { ISubmarketPricesProjection, ISubmarketProjection } from '@contexts/prices-projection';

import { formatCurrency } from '@utils/text';

import { Footer } from '@components/molecules/detailed-prices-projection/footer';
import { energyTypes, projectionYears } from '@components/molecules/form/upsert-prices-projection/helper';
import Layout, { LayoutProps } from '@components/atoms/layout';

import { EnergyTypesTableLabel } from './util';

export interface IPricesProjectionsTable {
  submarket: keyof ISubmarketPricesProjection;
  [key: number]: number | undefined;
}

export const parseSubmarketLabel = (submarket: keyof ISubmarketPricesProjection) => {
  if (submarket == 'northSubmarket') return 'Norte';
  else if (submarket == 'northeastSubmarket') return 'Nordeste';
  else if (submarket == 'southSubmarket') return 'Sul';
  else if (submarket == 'midwestSoutheastSubmarket') return 'Sudeste /Centro Oeste';
  else return '-';
};
const DetailedPricesProjectionPage: React.FC = () => {
  const { loading, setPricesProjectionId, pricesProjectionById } = usePricesProjections();
  const { setTraderId, traderById } = useTraders();
  const { id } = useParams();

  useEffect(() => {
    if (id && !pricesProjectionById) setPricesProjectionId(id);
    else if (pricesProjectionById?.retailTraderId) setTraderId(pricesProjectionById.retailTraderId);
  }, [id, pricesProjectionById]);

  const tableData = {
    i5: [],
    i100: [],
  };

  // @ts-expect-error -> Dynamic type
  const yearsColumnsGenerator: ColumnOptions<T>[] = Object.values(projectionYears()).map((projectionYear) => ({
    accessor: `${projectionYear}`,
    header: `${projectionYear}`,
    renderAsElement: (entry) => (entry[projectionYear] ? formatCurrency(entry[projectionYear]?.toString()) : '-'), // TODO -> PARSE CURRENCY FORMAR
  }));

  const tableColumns: ColumnOptions<IPricesProjectionsTable>[] = [
    {
      accessor: 'submarket',
      header: 'Submercado',
      renderAsElement: (entry) => parseSubmarketLabel(entry.submarket),
    },
    ...yearsColumnsGenerator,
  ];

  Object.keys(tableData).map((energyType) => {
    pricesProjectionById?.submarkets &&
      Object.entries(pricesProjectionById.submarkets).map((submarket: [string, ISubmarketProjection]) => {
        // @ts-expect-error -> dynamic indexes
        tableData[energyType].push({ submarket: submarket[0], ...submarket[1][energyType] });
      });
  });

  const baseLinks = [
    {
      label: 'Histórico',
      url: HOME_PATH,
    },
    {
      label: `${traderById?.name}`,
      url: '',
    },
    {
      label: 'Visualizar',
      url: '#',
    },
  ];
  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [...baseLinks];

  return (
    <Layout title="Visualizar Projeção" navigationHistoryLinks={navigationHistoryLinks} darkerBackground>
      <div className="col-span-full">
        {!loading && pricesProjectionById ? (
          <>
            <div className="flex flex-col gap-8 mb-8">
              {Object.entries(energyTypes).map((energyType) => (
                <div key={energyType.toString()}>
                  <div className="mb-4 ml-5 text-heading-xsmall font-bold">{energyType[1]}</div>
                  {/* @ts-expect-error -> dynamic indexes */}
                  <Table data={tableData[energyType[0]]} tableColumns={tableColumns} />
                  <div className="mt-3">
                    <label className="ml-5 text-paragraph-medium font-normal text-neutral-50">
                      {EnergyTypesTableLabel(pricesProjectionById.charges, pricesProjectionById.expirationDate)}
                    </label>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex ml-6 mt-13 mb-6 gap-6">
              <Footer
                expirationDate={pricesProjectionById?.expirationDate ?? undefined}
                idOfPriceProjection={pricesProjectionById?.id}
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col gap-12">
            <Skeleton count={6} enableAnimation />
            <Skeleton count={6} enableAnimation />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default DetailedPricesProjectionPage;
