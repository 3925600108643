import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import configuration from '@config';

import { UserInfo } from '@contexts/users/types';
import { IPricesProjectionPayload } from '@contexts/prices-projection/types';
import usePricesProjections from '@hooks/use-prices-projection';

import { EnergyTypeEnum, SubmarketEnum } from '@src/utils/translators/proposal';

import { CheckboxComponent } from '@components/atoms/checkbox';
import SubmarketSection from '@components/molecules/upsert-prices-projection/submarket-section';
import { ProgressComponent } from '@components/atoms/progress';
import ButtonComponent from '@components/atoms/button';
import ContractConditionsSection from '@components/molecules/upsert-prices-projection/contract-conditions-section';

import {
  parsePricesProjectionPayload,
  submarkets,
  unparsePricesProjection,
  pricesProjectionFormDefaultValues,
  UpsertPricesProjectionFormSteps,
} from './helper';
import { validatePricesProjectionPayload } from './validation';

interface IFormUpsertPricesProjection {
  user: UserInfo;
  onFormSuccess: () => void;
  onFormFailure: () => void;
  setLayoutTitle: (title: string) => void;
  setOpenConditionsOfSigningsModal: (open: boolean) => void;
}
export function FormUpsertPricesProjection({
  user,
  onFormSuccess,
  onFormFailure,
  setLayoutTitle,
  setOpenConditionsOfSigningsModal,
}: IFormUpsertPricesProjection) {
  const { pricesProjectionById, setPricesProjectionId, createPricesProjectionHandler } = usePricesProjections();
  const { id: pricesProjectionId } = useParams();
  const [isChecked, setIsChecked] = useState({
    INCENTIVIZED_50: false,
    INCENTIVIZED_100: false,
  });
  const methods = useForm<IPricesProjectionPayload>({
    defaultValues: pricesProjectionById
      ? unparsePricesProjection(pricesProjectionById)
      : pricesProjectionFormDefaultValues,
    mode: 'all',
  });
  const {
    trigger,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
    reset,
  } = methods;
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<number>(1);
  const currentStep = UpsertPricesProjectionFormSteps[step - 1];
  const navigate = useNavigate();
  const nextStep = async () => {
    const submarketKeys = ['northSubmarket', 'southSubmarket', 'northeastSubmarket', 'midwestSoutheastSubmarket'];

    const fieldsToClearErrors: string[] = submarketKeys.map(
      (submarketKey) => `submarkets.${submarketKey}.${currentStep.energyTypeKey}`,
    );
    const triggerResult = await trigger(fieldsToClearErrors as Array<keyof IPricesProjectionPayload>);
    triggerResult && setStep(step + 1);
  };
  const prevStep = () => {
    if (step === 1) navigate(-1);
    else setStep(step - 1);
  };

  const onSubmit = (values: IPricesProjectionPayload) => {
    setLoading(true);

    const isValidDate = validatePricesProjectionPayload(values, setError, setLoading);
    if (!isValidDate) return;

    const parsedPricesProjectionPayload = parsePricesProjectionPayload(values, user, configuration.MAIN_PARTNER_ID);
    createPricesProjectionHandler(parsedPricesProjectionPayload, pricesProjectionId)
      .then((response) => {
        if (response.data.id) {
          onFormSuccess();
        } else {
          onFormFailure();
        }
      })
      .catch(() => onFormFailure());

    setLoading(false);
  };

  const handleCheckboxChange = (energyType: 'INCENTIVIZED_50' | 'INCENTIVIZED_100', checked: boolean) => {
    setIsChecked((prevState) => ({
      INCENTIVIZED_50: energyType === 'INCENTIVIZED_50' ? checked : prevState.INCENTIVIZED_50,
      INCENTIVIZED_100: energyType === 'INCENTIVIZED_100' ? checked : prevState.INCENTIVIZED_100,
    }));

    if (checked) {
      const submarketKeys = ['northSubmarket', 'southSubmarket', 'northeastSubmarket', 'midwestSoutheastSubmarket'];

      const fieldsToClearErrors: string[] = submarketKeys.map(
        (submarketKey) => `submarkets.${submarketKey}.${currentStep.energyTypeKey}`,
      );
      clearErrors(fieldsToClearErrors as Array<keyof IPricesProjectionPayload>);
    }
  };

  useEffect(() => {
    if (pricesProjectionId) setPricesProjectionId(pricesProjectionId);
  }, [pricesProjectionId]);

  useEffect(() => {
    if (pricesProjectionById && step === 1) {
      reset(unparsePricesProjection(pricesProjectionById));
    }
  }, [pricesProjectionById]);

  useEffect(() => {
    const layoutTitle = currentStep ? currentStep.title : 'Energia incentivada';
    setLayoutTitle(layoutTitle);
  }, [step]);

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        {step === 1 || step === 2 ? (
          <div className="flex flex-col w-full gap-7 mb-10">
            {submarkets.map((submarket) => (
              <SubmarketSection
                key={`submarket-form-${submarket.name}-${currentStep.energyType}`}
                submarket={submarket}
                description={currentStep.description}
                isRequired={false}
                energyType={currentStep.energyType as keyof typeof EnergyTypeEnum}
                optionalSection={isChecked}
              />
            ))}
          </div>
        ) : (
          <div>
            <div className="my-5">
              <ContractConditionsSection
                title={'Condições de contratação*'}
                description={'Dados sobre as condições comerciais de contratação de energia.*'}
                isRequired={true}
              />
            </div>
            <label className="text-paragraph-medium font-normal text-neutral-50">
              *As premissas estabelecidas seguem as condições comerciais de contratação,
              <a href="#" onClick={() => setOpenConditionsOfSigningsModal(true)} className="text-green-500">
                {' '}
                acesse-as por aqui.
              </a>
            </label>
          </div>
        )}
        {step < 3 && (
          <div className="grid grid-cols-12 max-w-screen-xl my-20">
            <div className="col-span-4">
              <CheckboxComponent.Root className="mb-20" size="large">
                <CheckboxComponent.Indicator
                  checked={isChecked[currentStep.energyType as keyof typeof isChecked]}
                  onCheckedChange={(checked) =>
                    handleCheckboxChange(currentStep.energyType as keyof typeof isChecked, checked)
                  }
                  disabled={
                    (currentStep.energyType === 'INCENTIVIZED_50' && isChecked.INCENTIVIZED_100) ||
                    (currentStep.energyType === 'INCENTIVIZED_100' && isChecked.INCENTIVIZED_50)
                  }
                />
                <CheckboxComponent.Label
                  content="Opto por não preencher este tipo de energia"
                  disabled={
                    (currentStep.energyType === 'INCENTIVIZED_50' && isChecked.INCENTIVIZED_100) ||
                    (currentStep.energyType === 'INCENTIVIZED_100' && isChecked.INCENTIVIZED_50)
                  }
                />
              </CheckboxComponent.Root>
            </div>
          </div>
        )}
        <div className="flex justify-between">
          <div className="flex gap-4 text-paragraph-medium font-normal">
            {step === 3 ? (
              <ButtonComponent
                key="form-upsert-prices-submit-button"
                kind="primary"
                label="Enviar"
                type="submit"
                loading={loading}
                disabled={(errors['submarkets'] && true) || loading}
              />
            ) : (
              <ButtonComponent
                key="form-upsert-prices-next-step-button"
                kind="primary"
                label="Avançar"
                type="button"
                onClick={nextStep}
                disabled={errors.submarkets && true}
              />
            )}
            <ButtonComponent
              key="form-upsert-prices-prev-step-button"
              kind="secondary"
              label={step === 1 ? ' Cancelar' : 'Voltar'}
              type="button"
              onClick={prevStep}
              disabled={(errors.submarkets && true) || loading}
            />
          </div>
          <div className="w-1/3">
            <ProgressComponent.Root>
              <ProgressComponent.Bar size="large" progressValue={currentStep.progressValue} />
            </ProgressComponent.Root>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
