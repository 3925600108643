import { TradersListGraphQLData, TraderGraphQLData, Trader } from './types';

export class TradersManager {
  rawData: TradersListGraphQLData;
  traders: Trader[];

  constructor(rawData: TradersListGraphQLData) {
    this.rawData = rawData;
    this.traders = rawData.traders?.data;
  }
}

export class TraderManager {
  rawData: TraderGraphQLData;
  trader: Trader;

  constructor(rawData: TraderGraphQLData) {
    this.rawData = rawData;
    this.trader = rawData.trader;
  }
}
