import React from 'react';
import { get } from 'lodash';

//@ts-expect-error: versioning bug
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { HeroIcon } from '@components/atoms/hero-icon';

import style from './style.module.css';

export interface InputNumericContentProps<T extends FieldValues> {
  fieldName: FieldPath<T>;
  inputRef?: ((el: HTMLInputElement) => void) | React.Ref<any> | undefined;
  rules?: RegisterOptions;
  id?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  formatProps: Pick<
    NumericFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'valueIsNumericString'
  >;
}

export function InputNumericContent<T extends FieldValues>({
  fieldName,
  inputRef,
  rules,
  formatProps: {
    placeholder,
    prefix,
    suffix,
    allowNegative = false,
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = true,
    valueIsNumericString,
  },
  ...attrs
}: InputNumericContentProps<T>) {
  const { id, className, disabled, required } = attrs;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldError = get(errors, fieldName)?.message as string | undefined;

  const focusClass = fieldError ? style.contentFocusOnError : style.contentFocus;

  const dynamicClasses = [focusClass, className].join(' ');

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div className="relative flex flex-col">
          <NumericFormat
            id={id}
            name={name}
            disabled={disabled}
            className={`${style.content} ${dynamicClasses} rounded-pill focus:ring-0 focus:outline-2 focus:outline-brand-primary-50`}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            valueIsNumericString={valueIsNumericString}
            prefix={prefix}
            suffix={suffix}
            value={value === undefined ? '' : value}
            placeholder={placeholder}
            required={required}
            onBlur={onBlur}
            onValueChange={(v: any) => {
              if (valueIsNumericString) {
                return onChange(v.value);
              }

              const value = v.floatValue === undefined ? null : v.floatValue;

              if (value && !allowNegative) {
                onChange(Math.abs(value));
              } else {
                onChange(value);
              }
            }}
            getInputRef={inputRef}
          />
          {fieldError && <span className="text-paragraph-small text-danger-60">{fieldError}</span>}
        </div>
      )}
    />
  );
}
