import React, { ReactNode } from 'react';

import style from './style.module.css';

interface InputRootProps {
  children: ReactNode;
}

export const InputRoot: React.FC<InputRootProps> = ({ children }) => {
  return <div className={style.root}>{children}</div>;
};
