import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { createContext } from 'use-context-selector';

import { GET_TRADERS_QUERY, GET_TRADER_FOR_USER_QUERY, GET_TRADER_QUERY } from './queries';
import { Trader, TraderContextType } from './types';
import { TraderManager, TradersManager } from './manager';

export const TraderContext = createContext({} as TraderContextType);

interface Provider {
  children: React.ReactNode;
}

const TradersProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [traders, setTraders] = useState<Array<Trader>>([]);
  const [traderById, setTraderById] = useState<Trader>();
  const [traderId, setTraderId] = useState<string>('');

  const [getTraders, { called: calledGetTraders, loading }] = useLazyQuery(GET_TRADERS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new TradersManager(data);
      setTraders(manager.traders);
    },
    onError: () => {
      setTraders([]);
    },
  });

  const [getTraderById] = useLazyQuery(GET_TRADER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const manager = new TraderManager(data);
      setTraderById(manager.trader);
    },
    onError: () => {
      setTraderById(undefined);
    },
    variables: {
      traderId: traderId,
    },
  });

  const [getTraderForUser] = useLazyQuery(GET_TRADER_FOR_USER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const trader = data.getTraderForUser.trader;
      setTraderById(trader);
    },
  });

  function getTraderForUserHandler(userId: string) {
    getTraderForUser({
      variables: {
        userId,
      },
    });
  }

  if (!calledGetTraders) getTraders();

  useEffect(() => {
    if (traderId) getTraderById();
  }, [traderId]);

  return (
    <TraderContext.Provider
      value={{
        getTraders,
        getTraderForUserHandler,
        setTraderId,
        traderById,
        traders,
        loading,
      }}
    >
      {children}
    </TraderContext.Provider>
  );
};

export default TradersProvider;
