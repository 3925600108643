import React from 'react';

import { useAuth } from '@src/auth-wrapper';

import { ListSkeleton } from '@components/molecules/general/list-panel-skeleton';

import { PriceProjectionsHistoryPage } from './price-projections-history';

const HomePage: React.FC = () => {
  const { loading } = useAuth();

  if (loading)
    return (
      <div className="h-screen">
        <ListSkeleton isAdmin={false} />
      </div>
    );

  return <PriceProjectionsHistoryPage />;
};

export default HomePage;
