import moment from 'moment';
import { ButtonIcon } from '@clarke-energia/foton';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { DETAILED_PRICES_PROJECTION_PATH, UPSERT_PRICES_PROJECTION_PATH } from '@routers/constants';
import { IPricesProjection } from '@contexts/prices-projection';

export function PriceProjectionsHistoryTableButtonsColumn({ priceProjection }: { priceProjection: IPricesProjection }) {
  const disable = (priceProjection: IPricesProjection): boolean => {
    return moment(priceProjection.expirationDate, 'YYYY/MM/DD hh:mm') <= moment();
  };

  const navigate = useNavigate();

  return (
    <td className="col-sapn-1 flex justify-between min-h-full my-2">
      <ButtonIcon
        kind="ghost"
        icon="PencilSquareIcon"
        onClick={() => navigate(`${UPSERT_PRICES_PROJECTION_PATH}/${priceProjection.id}`)}
        disabled={disable(priceProjection)}
        className={twMerge(disable(priceProjection) ? 'text-neutral-20' : 'hover:bg-neutral-10', 'h-fit self-center')}
      />
      <ButtonIcon
        kind="ghost"
        icon="EyeIcon"
        onClick={() => navigate(`${DETAILED_PRICES_PROJECTION_PATH}/${priceProjection.id}`)}
        className="hover:bg-neutral-10 h-fit self-center"
      />
    </td>
  );
}
