import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import configuration from '@config';

import { useAuth } from './auth-wrapper';

interface ApolloWrapperProps {
  children: JSX.Element;
}

function ApolloWrapper({ children }: ApolloWrapperProps) {
  const {
    authStatus: { accessToken },
  } = useAuth();

  const httpLink = createHttpLink({
    uri: configuration.GATEWAY_API_HOST,
  });

  const authLink = setContext((_, { headers, ...rest }) => {
    if (!accessToken) return { headers, ...rest };
    return {
      ...rest,
      headers: { ...headers, Authorization: `Bearer ${accessToken}` },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const { variables, operationName } = operation;
    const context = { name: operationName, values: variables };

    if (networkError) {
      context.values = {
        ...context.values,
        errorType: 'network',
        rawErrorObject: JSON.stringify(networkError),
      };
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        context.values = {
          ...context.values,
          errorType: 'graphql',
          rawErrorObject: JSON.stringify(graphQLError),
        };
      });
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloWrapper;
