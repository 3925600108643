import React from 'react';

import { HeroIcon } from '@components/atoms/hero-icon';

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Total number of rows in a table, that is, its length
   */
  totalRows: number;
  /**
   * How many rows will be shown
   */
  rowsByPage: number;
  /**
   * current page state
   */
  currentPage: number;
  /**
   * function to set current page state
   */
  setCurrentPage: (page: number) => void;
  /**
   * Custom attributes (e.g. test selectors like `data-cy="test"`).
   * Be careful to not overlap it with other default attributes.
   * */
  customAttrs?: Record<string, string>;
}

const Pagination: React.FC<PaginationProps> = ({
  totalRows,
  rowsByPage,
  currentPage,
  setCurrentPage,
  customAttrs,
  ...attrs
}) => {
  const { className, ...restAttrs } = attrs;
  const totalPages = Math.ceil(totalRows / rowsByPage);
  const indexOfLastPage = currentPage * rowsByPage;
  const indexOfFirstPage = indexOfLastPage - rowsByPage + 1;

  const nextPage = () => {
    if (currentPage !== totalPages) setCurrentPage(currentPage + 1);
  };
  const previousPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div
      className={`flex items-center min-w-full justify-between  bg-neutral-10 pl-4 sm:pl-6 text-caption-medium sm:text-paragraph-small ${className}`}
      {...customAttrs}
      {...restAttrs}
    >
      <div className="flex flex-1 items-center justify-center">
        <div
          className="flex items-center justify-center w-5 sm:w-7 h-full cursor-pointer hover:bg-primary-10"
          onClick={() => previousPage()}
        >
          <HeroIcon icon={'ChevronLeftIcon'} className={'w-4 h-full sm:w-5'} />
        </div>
        <div className="flex items-center h-6 sm:h-7 w-28">
          {currentPage !== 1 && (
            <div
              className={`flex items-center justify-center w-5 sm:w-7 h-full cursor-pointer hover:bg-primary-10  ${
                currentPage === 1 ? 'bg-primary-10' : ''
              } `}
              onClick={() => setCurrentPage(1)}
            >
              1
            </div>
          )}
          {currentPage === totalPages && currentPage !== 2 && totalPages !== 1 && (
            <div
              className="flex items-center justify-center w-5 sm:w-7 h-full cursor-pointer hover:bg-primary-10"
              onClick={() => setCurrentPage(2)}
            >
              2
            </div>
          )}
          {currentPage > 2 && totalPages > 3 && (
            <div className="flex items-center justify-center w-5 sm:w-7 h-full ">...</div>
          )}
          <div
            className={`flex items-center justify-center w-5 sm:w-7 h-full cursor-pointer hover:bg-primary-10 text-primary-80 font-bold rounded-xl ${
              currentPage === currentPage ? 'bg-primary-10' : ''
            } `}
            onClick={() => setCurrentPage(1)}
          >
            {`${currentPage < 10 ? ` ${currentPage}` : currentPage}`}
          </div>
          {currentPage < totalPages - 1 && (
            <>
              <div
                className="flex items-center justify-center w-5 sm:w-7 h-full cursor-pointer hover:bg-primary-10"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                {currentPage + 1}
              </div>
            </>
          )}
          {currentPage < totalPages - 2 && (
            <div className="flex items-center justify-center w-5 sm:w-7 h-full">...</div>
          )}
          {currentPage !== totalPages && totalPages !== 1 && (
            <div
              className="flex items-center justify-center w-5 sm:w-7 h-full cursor-pointer hover:bg-primary-10"
              onClick={() => setCurrentPage(totalPages)}
            >
              {totalPages}
            </div>
          )}
        </div>
        <div
          className="flex items-center w-5 sm:w-7 h-full justify-center cursor-pointer hover:bg-primary-10"
          onClick={() => nextPage()}
        >
          <HeroIcon icon={'ChevronRightIcon'} className={'w-4 h-full sm:w-5'} />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
