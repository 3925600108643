const configuration = {
  AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  AMPLITUDE_INITIALIZED: false,
  CALCULATOR_API_HOST: import.meta.env.VITE_CALCULATOR_API_HOST,
  CALCULATOR_API_KEY: import.meta.env.VITE_CALCULATOR_API_KEY,
  CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  GATEWAY_API_HOST: import.meta.env.VITE_GATEWAY_API_HOST,
  MAIN_PARTNER_ID: import.meta.env.VITE_MAIN_PARTNER_ID,
};

// canary check
if (!configuration.GATEWAY_API_HOST) {
  throw `Invalid GATEWAY_APY_HOST! value found: ${configuration.GATEWAY_API_HOST}`;
}

export default configuration;
