import React from 'react';

import { IPricesProjectionPayload } from '@contexts/prices-projection';

import { Input } from '@components/atoms/form/input';
import SubmarketFormContainer from '@components/molecules/form';

export interface ContractConditionsSectionProps {
  title: string;
  description: string;
  isRequired: boolean;
}

const ContractConditionsSection: React.FC<ContractConditionsSectionProps> = ({ title, description, isRequired }) => {
  return (
    <SubmarketFormContainer title={title} description={description}>
      <div className="grid grid-cols-1 gap-4 w-screen max-w-screen-md justify-between ml-1">
        <div>
          <Input.Root>
            <Input.Label
              content="Encargos setoriais"
              className="text-paragraph-small font-normal"
              tooltipContent="Esse valor será decrescido do preço da energia acima em caso de não cobertura de encargos."
              required={isRequired}
            />
            <Input.NumericContent<IPricesProjectionPayload>
              fieldName="charges"
              className="max-w-80"
              formatProps={{
                placeholder: 'R$ 00,00',
                prefix: 'R$ ',
                allowNegative: false,
                fixedDecimalScale: true,
                decimalScale: 2,
                suffix: '',
                thousandSeparator: '.',
                decimalSeparator: ',',
                valueIsNumericString: false,
              }}
            />
          </Input.Root>
        </div>
        <div>
          <Input.Label content="Validade" className="text-paragraph-small font-normal" required={isRequired} />
          <Input.Date<IPricesProjectionPayload> fieldName="expirationDate" className="max-w-full" />
        </div>
      </div>
    </SubmarketFormContainer>
  );
};

export default ContractConditionsSection;
