import moment from 'moment';
import { formatNumeral } from '@utils/numberal';

export const EnergyTypesTableLabel = (charges?: number | null, expirationDate?: string | null): string => {
  const labelBase = 'Os encargos setoriais desse período ';
  let labelWithExpirationDate = '';

  if (!charges && !expirationDate) {
    return '';
  }

  const labelWithCharges = charges ? `são de  ${formatNumeral(charges, '$ 0,0.00')}` : '';

  if (charges && expirationDate) {
    labelWithExpirationDate = ` e respeitam o período de validade de ${moment(expirationDate).format('DD/MM/YYYY')}`;
  } else if (!charges && expirationDate) {
    labelWithExpirationDate = `respeitam o período de validade de ${moment(expirationDate).format('DD/MM/YYYY')}`;
  }
  const label = `${labelBase}${labelWithCharges}${labelWithExpirationDate}.`;
  return label;
};
