import * as Tooltip from '@radix-ui/react-tooltip';
import { TooltipTrigger } from './trigger';
import { TooltipContent } from './content';

export const TooltipComponent = {
  Provider: Tooltip.Provider,
  Root: Tooltip.Root,
  Trigger: TooltipTrigger,
  Portal: Tooltip.Portal,
  Content: TooltipContent,
};
