import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import { get } from 'lodash';

import { Controller, useFormContext, RegisterOptions, FieldValues, FieldPath, PathValue, Path } from 'react-hook-form';
import style from '../style.module.css';
import DateInput from './raw-input';

export interface InputDateContentProps<T extends FieldValues> {
  fieldName: FieldPath<T>;
  rules?: RegisterOptions;
  error?: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

export function InputDateContent<T extends FieldValues>({
  fieldName,
  rules,
  error,
  ...attrs
}: InputDateContentProps<T>) {
  const { id, className, disabled, required } = attrs;

  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<T>();

  const fieldError = get(errors, fieldName)?.message as string | undefined;
  const watchedValue = watch(fieldName);

  const [date, setDate] = useState<string>('');
  const [time, setTime] = useState<string>('');

  useEffect(() => {
    if (watchedValue) {
      const [datePart, timePart] = watchedValue.includes(' ') ? watchedValue.split(' ') : watchedValue.split('T');
      setDate(datePart);
      setTime(timePart);
    }
  }, [watchedValue]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    const newDate = e.target.value;
    setDate(newDate);
    onChange(newDate);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    const newTime = e.target.value;
    setTime(newTime);
    onChange(newTime);
  };

  useEffect(() => {
    const dateTime = `${date} ${time}`;
    if (date && time && moment(dateTime, 'YYYY-MM-DD HH:mm').isValid()) {
      setValue(fieldName, dateTime as PathValue<T, Path<T>>);
    }
  }, [watchedValue, date, time]);

  const focusClass = fieldError ? style.contentFocusOnError : style.contentFocus;
  const dynamicClasses = twMerge(style.content, focusClass, className, 'min-w-80 -top-4 -left-5 sm:rounded-pill');

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { name, onChange } }) => (
        <div className="flex min-w-full ml-4">
          <DateInput
            id={id}
            name={name}
            type="date"
            label=""
            defaultValue={date}
            required={required ?? false}
            error={fieldError}
            onChange={(e) => handleDateChange(e, onChange)}
            className={twMerge(dynamicClasses, 'h-10')}
            disabled={disabled}
          />
          <input
            type="time"
            id="time"
            name="time"
            value={time}
            onChange={(e) => handleTimeChange(e, onChange)}
            className={dynamicClasses}
            disabled={disabled}
          />
        </div>
      )}
    />
  );
}
