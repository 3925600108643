import { useContextSelector } from 'use-context-selector';
import { PricesProjectionContext, PricesProjectionContextType } from '@contexts/prices-projection';

const usePricesProjections = (): PricesProjectionContextType => {
  const setPartnerId = useContextSelector(
    PricesProjectionContext,
    (pricesProjections) => pricesProjections.setPartnerId,
  );
  const partnerId = useContextSelector(PricesProjectionContext, (pricesProjections) => pricesProjections.partnerId);
  const setPricesProjectionId = useContextSelector(
    PricesProjectionContext,
    (pricesProjection) => pricesProjection.setPricesProjectionId,
  );
  const getPartnerPricesProjectionsHandler = useContextSelector(
    PricesProjectionContext,
    (pricesProjections) => pricesProjections.getPartnerPricesProjectionsHandler,
  );
  const getPricesProjectionByIdHandler = useContextSelector(
    PricesProjectionContext,
    (pricesProjections) => pricesProjections.getPricesProjectionByIdHandler,
  );
  const pricesProjections = useContextSelector(
    PricesProjectionContext,
    (pricesProjections) => pricesProjections.pricesProjections,
  );
  const pricesProjectionById = useContextSelector(
    PricesProjectionContext,
    (pricesProjectionById) => pricesProjectionById.pricesProjectionById,
  );
  const createPricesProjectionHandler = useContextSelector(
    PricesProjectionContext,
    (pricesProjection) => pricesProjection.createPricesProjectionHandler,
  );
  const loading = useContextSelector(PricesProjectionContext, (pricesProjection) => pricesProjection.loading);

  return {
    partnerId,
    setPartnerId,
    createPricesProjectionHandler,
    pricesProjections,
    getPartnerPricesProjectionsHandler,
    pricesProjectionById,
    getPricesProjectionByIdHandler,
    setPricesProjectionId,
    loading,
  };
};

export default usePricesProjections;
