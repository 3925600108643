import React from 'react';

import { Tooltip } from '@clarke-energia/foton';

export interface DateInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'ref' | 'type'> {
  label: string;
  error?: string;
  required?: boolean;
  tooltipContent?: string;
  customAttrs?: Record<string, string>;
  type: string;
}

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ type, label, error, required, tooltipContent, customAttrs, ...attrs }, ref) => {
    const { id, disabled, placeholder, className, ...restAttrs } = attrs;
    const disabledClass = disabled ? 'text-neutral-50' : 'text-black';

    const borderClass = 'border-neutral-30';
    const errorClass = disabled
      ? 'outline-none border ring-0 bg-neutral-10'
      : error
      ? 'outline-none border border-danger-60 ring-danger-60'
      : '';
    const focusClass = error
      ? 'focus:border focus:outline-none focus:border-danger-60 focus:ring-danger-60'
      : 'focus:border focus:outline-none focus:border-primary-60 focus:ring-primary-60';

    const dynamicClasses = [borderClass, errorClass, focusClass, className].join(' ');

    return (
      <div className={`flex relative flex-col ${className}`}>
        <div className="flex gap-2 mb-2 items-center">
          <label htmlFor={id} className={`text-paragraph-medium ${disabledClass}`}>
            {label}
            {required && <span className="text-danger-60">*</span>}
          </label>
          {tooltipContent && <Tooltip content={tooltipContent} />}
        </div>
        <input
          type={type}
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          ref={ref}
          className={`rounded-small text-paragraph-medium py-3 h-7 ${dynamicClasses}`}
          {...restAttrs}
          {...customAttrs}
        />
        {error && <span className="text-paragraph-small text-danger-60">{error}</span>}
      </div>
    );
  },
);
DateInput.displayName = 'DateInput';

export default DateInput;
