import React from 'react';
import { useFormContext } from 'react-hook-form';

import style from './style.module.css';

export interface ErrorMessageProps {
  fieldName: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ fieldName }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[fieldName]?.message as string;

  return <div className={style.messageError}>{errorMessage}</div>;
};
