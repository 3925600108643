import Skeleton from 'react-loading-skeleton';

import usePricesProjections from '@hooks/use-prices-projection';

import Pagination from '@components/molecules/general/pagination';
import { PriceProjectionsHistoryTableHead } from '@components/atoms/price-projections-history/table/head';
import { PriceProjectionsHistoryTableBody } from '@components/molecules/price-projections-history/table/body';

export function PriceProjectionsHistoryTable() {
  const { loading: loadingProjections, pricesProjections, getPartnerPricesProjectionsHandler } = usePricesProjections();

  const handleChangePage = (page: number) => {
    getPartnerPricesProjectionsHandler(page);
  };

  return (
    <div className="flex flex-col">
      {loadingProjections ? (
        <Skeleton count={0} enableAnimation />
      ) : (
        pricesProjections && (
          <>
            <table className="grid m-5 table-auto">
              <PriceProjectionsHistoryTableHead />
              <PriceProjectionsHistoryTableBody pricesProjections={pricesProjections} />
            </table>
            <div className="mb-5">
              <Pagination
                currentPage={pricesProjections.page}
                rowsByPage={pricesProjections.limit}
                totalRows={pricesProjections.total}
                setCurrentPage={handleChangePage}
              />
            </div>
          </>
        )
      )}
    </div>
  );
}
