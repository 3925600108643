import React from 'react';

import ModalComponent from '@components/atoms/modal';

interface IModalConditionsOfSignings {
  isModalOpenLabel: boolean;
  setIsModalOpenLabel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalConditionsOfSignings = ({ isModalOpenLabel, setIsModalOpenLabel }: IModalConditionsOfSignings) => {
  const handleCloseModalLabel = () => {
    setIsModalOpenLabel(false);
  };

  function ChildrenDescription() {
    return (
      <ul className="pl-6">
        <li className="list-disc">
          <b>Flexibilidade:</b> +/-100%;
        </li>
        <li className="list-disc">
          <b>Modulação:</b> conforme a carga;
        </li>
        <li className="list-disc">
          <b>Encargos setoriais:</b> curva base cobre 100% dos encargos;
        </li>
        <li className="list-disc">
          <b>Garantia:</b> sem garantia;
        </li>
        <li className="list-disc">
          <b>Atraso de migração:</b> M+6.
        </li>
      </ul>
    );
  }

  return (
    <ModalComponent
      title="Condições comerciais de contratação"
      description=""
      childrenDescription={<ChildrenDescription />}
      isOpen={isModalOpenLabel}
      setOpen={setIsModalOpenLabel}
      className="text-start"
      buttonsProps={[
        {
          label: 'Voltar',
          kind: 'secondary',
          size: '2',
          onClick: handleCloseModalLabel,
        },
        {
          label: 'OK, estou ciente',
          kind: 'primary',
          size: '2',
          onClick: handleCloseModalLabel,
        },
      ]}
    />
  );
};
