import { InputLabel } from './label';
import { InputNumericContent } from './numeric-content';
import { InputNumericMask } from './numeric-mask';
import { InputRoot } from './root';
import { InputTextContent } from './text-content';
import { ErrorMessage } from './error-message';
import { InputDateContent } from './date';

export const Input = {
  Root: InputRoot,
  Label: InputLabel,
  Date: InputDateContent,
  NumericContent: InputNumericContent,
  NumericMask: InputNumericMask,
  TextContent: InputTextContent,
  Error: ErrorMessage,
};
