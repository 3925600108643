import React, { ReactNode } from 'react';
import style from '@components/atoms/checkbox/style.module.css';
import { twMerge } from 'tailwind-merge';

export type ProgressSize = 'small' | 'medium' | 'large';

export interface ProgressProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'size'> {
  size: ProgressSize;
  progressValue: number;
}

interface ProgressRootProps {
  children: ReactNode;
}

export const ProgressRoot: React.FC<ProgressRootProps> = ({ children }) => {
  return <div className={twMerge(style.root)}>{children}</div>;
};
