import React from 'react';
import { twMerge } from 'tailwind-merge';
import * as Dialog from '@radix-ui/react-dialog';

import { HeroIcon, IHeroIcon } from '@components/atoms/hero-icon';
import ButtonComponent, { ButtonComponentProps } from '@components/atoms/button/index';

import style from './style.module.css';

export type IconColor = 'green' | 'red' | 'yellow';

interface ModalIcon {
  name: IHeroIcon['icon'];
  color: IconColor;
}
interface ModalComponentProps {
  icon?: ModalIcon;
  title: string;
  description?: string;
  childrenDescription?: React.ReactElement;
  buttonsProps?: ButtonComponentProps[];
  children?: React.ReactNode;
  isOpen: boolean;
  setOpen: (key: boolean) => void;
  className?: string;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  icon,
  title,
  description,
  childrenDescription,
  children,
  setOpen,
  isOpen,
  buttonsProps,
  className,
  ...props
}) => {
  const modalIconClass = icon ? style.DialogIconStatus : style.hidden;
  const modalTitleClass = icon ? style.DialogIconTitleStatus : style.DialogTitleStatus;
  const modalContent = icon ? style.DialogContentWithIcon : style.DialogContentNoIcon;

  const COLOR_ICON_NAME: Record<IconColor, string> = {
    green: style.DialogIconStatusAppearance_Green,
    yellow: style.DialogIconStatusAppearance_Yellow,
    red: style.DialogIconStatusAppearance_Red,
  };

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className={style.DialogOverlay} />
        <Dialog.Content
          className={twMerge(style.DialogContent, modalContent, className)}
          onOpenAutoFocus={(event) => event.preventDefault()}
        >
          {icon && (
            <div className={modalIconClass}>
              <HeroIcon
                icon={icon?.name as IHeroIcon['icon']}
                className={twMerge('place-self-center w-[60px] h-[60px]', COLOR_ICON_NAME[icon.color])}
              />
            </div>
          )}
          <div className="flex flex-col">
            <Dialog.Title className={twMerge(modalTitleClass, className)}>{title}</Dialog.Title>
            {description ? (
              <Dialog.Description className={twMerge(style.DialogDescription, className)}>
                {description}
              </Dialog.Description>
            ) : null}
            {childrenDescription && (
              <Dialog.Description className={twMerge(style.DialogDescription, className)}>
                {childrenDescription}
              </Dialog.Description>
            )}
          </div>
          <div className={twMerge(style.DialogContentAction, !children && style.hidden)} {...props}>
            {children}
          </div>
          {buttonsProps && (
            <div className={twMerge(style.ButtonsTogether, `grid grid-cols-${buttonsProps.length}`)}>
              {buttonsProps.length > 0 &&
                buttonsProps.map((btnProps) => (
                  <ButtonComponent
                    className="w-full h-9 flex items-center justify-center text-paragraph-medium font-normal p-0"
                    key={btnProps.label}
                    {...btnProps}
                  />
                ))}
            </div>
          )}
          <Dialog.Close asChild>
            <button className={style.IconButton} aria-label="Close" onClick={() => setOpen(false)}>
              <HeroIcon icon="XMarkIcon" className="place-self-center" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ModalComponent;
