import React from 'react';

interface TextProps {
  size?: string;
  color?: string;
  weight?: string;
  className?: string;
  children: React.ReactNode;
}

const Text = ({ size, color, weight, className, children }: TextProps) => {
  const classes = `${weight} ${size} ${color} ${className}`;

  return <span className={classes}>{children}</span>;
};

export default Text;
