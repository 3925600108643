import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';

import style from './style.module.css';
import { twMerge } from 'tailwind-merge';
import { HeroIcon } from '../hero-icon';
import { CheckboxProps } from './root';

interface CheckboxIndicatorProps extends CheckboxProps {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

export const CheckboxIndicator: React.FC<CheckboxIndicatorProps> = ({ checked, onCheckedChange, ...attrs }) => {
  const { id, disabled, size = 'small', className } = attrs;

  const sizeClassName = size === 'small' ? 'w-4 h-4' : 'w-6 h-6';
  const iconSize = size === 'small' ? 'w-3 h-3' : 'w-5 h-5';
  const disabledBorderStyle = disabled ? 'border-brand-gray-10' : 'border-brand-primary-0';
  const disableBgStyle = disabled ? 'bg-brand-gray-10' : 'bg-brand-primary-0';
  const fillClassName = checked ? `${disableBgStyle} appearance-none outline-none` : `border ${disabledBorderStyle}`;
  const dynamicClassName = [sizeClassName, fillClassName, className].join(' ');

  return (
    <Checkbox.Root
      id={id}
      className={twMerge(style.indicator, dynamicClassName)}
      disabled={disabled}
      checked={checked}
      onCheckedChange={onCheckedChange}
    >
      <Checkbox.Indicator>
        {checked && <HeroIcon className={twMerge(iconSize, 'text-white stroke-5')} icon="CheckIcon" />}
      </Checkbox.Indicator>
    </Checkbox.Root>
  );
};
