import React from 'react';
import { twMerge } from 'tailwind-merge';
import * as Tooltip from '@radix-ui/react-tooltip';

import { HeroIcon, IHeroIcon } from '@components/atoms/hero-icon';

import style from './style.module.css';

interface TooltipTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IHeroIcon['icon'];
  iconClassName?: string;
}

export const TooltipTrigger: React.FC<TooltipTriggerProps> = ({ icon, iconClassName, className, onClick }) => {
  return (
    <Tooltip.Trigger className={className} asChild type="button">
      <button onClick={onClick}>
        <HeroIcon className={twMerge(style.triggerIcon, iconClassName)} icon={icon} />
      </button>
    </Tooltip.Trigger>
  );
};
