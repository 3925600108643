import React from 'react';

import Text from '@components/atoms/text';

interface SubmarketFormContainerProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

const SubmarketFormContainer: React.FC<SubmarketFormContainerProps> = ({ title, description, children }) => {
  return (
    <div className="flex w-full gap-20">
      <div className="flex flex-col min-w-[264px] md:max-w-[264px] mb-4">
        <Text weight="font-bold" size="text-paragraph-large md:text-heading-xSmall" color="text-brand-gray-70">
          {title}
        </Text>
        <Text
          weight="font-light md:font-normal"
          size="text-caption-medium md:text-paragraph-medium"
          color="text-brand-gray-40"
        >
          {description}
        </Text>
      </div>
      <div className="w-full overflow-x-auto gap-5">{children}</div>
    </div>
  );
};

export default SubmarketFormContainer;
