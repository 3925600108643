import React from 'react';
import { Button, ButtonProps, Theme } from '@radix-ui/themes';
import { Responsive } from '@radix-ui/themes/dist/cjs/props/prop-def';
import { twMerge } from 'tailwind-merge';

import { HeroIcon, IHeroIcon } from '@src/components/atoms/hero-icon';

import style from './style.module.css';
import { LoadingIcon } from './loading-icon';

export type Variant = 'primary' | 'secondary' | 'tertiary';
export type IconPosition = 'none' | 'left' | 'right';

export interface ButtonComponentProps extends ButtonProps {
  kind: Variant;
  id?: string;
  icon?: IHeroIcon['icon'];
  label?: string;
  iconPosition?: IconPosition;
  size?: Responsive<'1' | '2' | '3'>;
  customAttrs?: Record<string, string>;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
}

const BUTTON_COLORS = {
  primary: style.primaryStyle,
  secondary: style.secondaryStyle,
  tertiary: style.tertiaryStyle,
};

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  kind,
  label,
  icon,
  iconPosition = 'left',
  size,
  className,
  loading,
  onClick,
  ...attrs
}) => {
  const btnStyle = BUTTON_COLORS[kind];

  const buttonClasses = twMerge(
    style.btnBehaviour,
    size === '1' ? 'p-2' : size === '2' ? 'py-3 px-4' : 'py-4 px-6',
    btnStyle,
    className,
  );

  return (
    <Theme>
      <Button
        role="button"
        aria-label={label}
        aria-disabled={attrs.disabled}
        size={size}
        onClick={() => !loading && onClick && onClick()}
        className={buttonClasses}
        {...attrs}
      >
        {loading && <LoadingIcon />}
        {icon && iconPosition === 'left' && <HeroIcon icon={icon} className="w-6 mr-3 self-center" />}
        {label}
        {icon && iconPosition === 'right' && <HeroIcon icon={icon} className="w-6 ml-3 self-center" />}
      </Button>
    </Theme>
  );
};

export default ButtonComponent;
