import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

import style from './style.module.css';
import { HeroIcon } from '@components/atoms/hero-icon';
import { twMerge } from 'tailwind-merge';

export interface InputTextContentProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'ref' | 'type'> {
  fieldName: string;
  value?: string | number;
  inputRef?: ((el: HTMLInputElement) => void) | React.Ref<any> | undefined;
  rules?: RegisterOptions;
  error?: string;
}

export const InputTextContent: React.FC<InputTextContentProps> = ({
  fieldName,
  value,
  inputRef,
  onChange,
  onBlur,
  error,
  rules,
  ...attrs
}) => {
  const { id, className, disabled, required, placeholder, ...restAttrs } = attrs;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldError = errors[fieldName];

  const focusClass = fieldError ? style.contentFocusOnError : style.contentFocus;

  const disabledBorder = disabled ? style.disabledBorder : '';

  const dynamicClasses = twMerge(focusClass, className, disabledBorder);

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      render={({ field: fieldProps }) => (
        <div className="relative inline-block">
          <input
            {...fieldProps}
            id={id}
            type="text"
            className={twMerge(style.content, dynamicClasses)}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            {...restAttrs}
            ref={inputRef}
          />
          {fieldError && (
            <HeroIcon
              className={twMerge(style.iconOnError, style.icon, style.iconPositionInsideContent)}
              icon={'ExclamationCircleIcon'}
            />
          )}
        </div>
      )}
    />
  );
};
