import _ from 'lodash';

type TransformKeyFunc = (key: string) => string;

const transformEntityRecursively = (
  entity: Record<string, any> | Array<any>,
  transformKey: TransformKeyFunc,
  preserveKeys: Set<string> = new Set(),
) => {
  const restartRecursionForChildEntity = (childEntity: any) => {
    if (_.isPlainObject(childEntity) || _.isArray(childEntity)) {
      transformEntityRecursively(childEntity, transformKey, preserveKeys);
    }
  };

  const transformList = (entityAsList: Array<any>) => {
    entityAsList.forEach((childEntity) => restartRecursionForChildEntity(childEntity));
  };

  const transformObject = (entityAsObject: Record<string, any>) => {
    Object.keys(entityAsObject).forEach((key) => {
      const transformedKey = transformKey(key);

      if (!preserveKeys.has(transformedKey)) {
        entityAsObject[transformedKey] = entityAsObject[key];
        if (key !== transformedKey) delete entityAsObject[key];
      }

      restartRecursionForChildEntity(entityAsObject[transformedKey]);
    });
  };

  if (_.isArray(entity)) transformList(entity);
  else if (_.isPlainObject(entity)) transformObject(entity);

  return entity;
};

export const transformObjectKeysToSnakeCase = (object: Record<string, any>, preserveKeys?: Set<string>) => {
  const objectClone = _.cloneDeep(object);
  return transformEntityRecursively(objectClone, _.snakeCase, preserveKeys);
};

export const transformObjectKeysToCamelCase = (object: Record<string, any>) => {
  const objectClone = _.cloneDeep(object);
  return transformEntityRecursively(objectClone, _.camelCase);
};