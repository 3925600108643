import React, { useState } from 'react';
import {
  Button,
  ButtonIcon,
  ButtonInline,
  ButtonProps,
  HeroIcon,
  PopUpList,
  SearchBar,
  SearchBarProps,
  Skeleton,
} from '@clarke-energia/foton';
import Tag, { TagProps } from './tag';
import ButtonComponent, { ButtonComponentProps } from './button';

export interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Layout title
   */
  title?: string;
  /**
   * Navigation history links (located above the title)
   */
  navigationHistoryLinks?: { url: string; label: string }[];
  /**
   * Alternative links (located on the title dropdown button)
   */
  alternativeLinks?: { url: string; label: string }[];
  /**
   * Buttons of the layout header
   */
  headerButtons?: ActionButtonsProps[];
  /**
   * Buttons of the layout header
   */
  headerTags?: TagProps[];
  /**
   * Search bar displayed in header
   */
  searchBar?: SearchBarProps;
  /**
   * Set layout background a little bit darker
   */
  darkerBackground?: boolean;
  /**
   * Set true to show Layout skeleton
   */
  loading?: boolean;
  /**
   * Custom attributes (e.g. test selectors like `data-cy="test"`).
   * Be careful to not overlap it with other default attributes.
   * */
  customAttrs?: Record<string, string>;
}
type ButtonType = 'buttonInline' | 'button' | 'buttonIcon';
type Shape = 'square' | 'circle';
export interface ActionButtonsProps extends ButtonComponentProps {
  buttonType?: ButtonType;
  shape?: Shape;
}
const Layout: React.FC<LayoutProps> = ({
  title,
  navigationHistoryLinks,
  alternativeLinks,
  headerButtons,
  headerTags,
  searchBar,
  customAttrs,
  loading,
  children,
  darkerBackground,
  ...attrs
}) => {
  const [showAlternativeLinks, setShowAlternativeLinks] = useState<boolean>(false);
  const { className } = attrs;

  return (
    <div className={`w-full h-full min-h-screen flex flex-col overflow-x-clip ${className}`} {...customAttrs}>
      <div id="layout-header" className="flex flex-col w-full gap-2 px-8 pt-6 pb-5 bg-white">
        <div
          id="navigation-links-history"
          className="flex flex-wrap w-full gap-1 text-paragraph-medium text-neutral-50"
        >
          {loading ? (
            <Skeleton className="w-[10rem] h-[1rem]" />
          ) : (
            <>
              {navigationHistoryLinks?.map((navigationHistoryLink, index) => {
                const isLastLink = index + 1 === navigationHistoryLinks.length;
                return (
                  <a
                    key={index}
                    href={navigationHistoryLink.url}
                    className={`whitespace-nowrap ${isLastLink ? 'font-bold' : 'font-normal'}`}
                  >
                    {`${navigationHistoryLink.label} ${!isLastLink ? '>' : ''}`}
                  </a>
                );
              })}
            </>
          )}
        </div>
        <div className="flex w-full flex-wrap gap-4 xl:flex-row justify-between ">
          <div
            className={`flex items-center gap-4 relative ${
              headerTags || headerButtons || searchBar ? 'w-full md:w-1/3 xl:w-1/2' : 'w-fit'
            }`}
          >
            {loading ? (
              <Skeleton className="w-[24rem] h-9" />
            ) : (
              <h1 className="text-heading-2xlarge font-black truncate">{title}</h1>
            )}
            {alternativeLinks && (
              <HeroIcon
                icon="ChevronDownIcon"
                onClick={() => setShowAlternativeLinks(!showAlternativeLinks)}
                className={`w-7 cursor-pointer rounded-pill text-black hover:bg-neutral-10 p-3 ${
                  showAlternativeLinks ? 'rotate-180' : 'rotate-0'
                }`}
              />
            )}
            {alternativeLinks && (
              <PopUpList
                items={alternativeLinks.map((alternativeLink) => ({
                  label: alternativeLink.label,
                  onClick: () => (location.href = alternativeLink.url),
                }))}
                show={showAlternativeLinks}
                className="top-10"
              />
            )}
          </div>
          <div className="w-full flex flex-col gap-4 h-full self-start md:w-fit md:flex-row md:self-center">
            {(headerTags || headerButtons || searchBar) && loading ? (
              <Skeleton className="w-[16rem] h-9" />
            ) : (
              <>
                {searchBar && <SearchBar iconPosition="right" {...searchBar} />}
                {headerTags?.map((headerTag, index) => (
                  <Tag key={index} {...headerTag} />
                ))}
                {headerButtons?.map((headerButton, index) => (
                  <ButtonComponent
                    key={`layout-button-${index}`}
                    className={headerButton.className}
                    {...headerButton}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`w-full h-full py-8 overflow-x-clip ${darkerBackground && 'bg-[#F2F2F2]'}`}>
        <div className="grid grid-cols-12 w-full h-full px-4 md:px-8 pb-16 bg-[#F2F2F2] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
