import { useEffect, useState } from 'react';
import { LayoutProps } from '@clarke-energia/foton';
import { useNavigate } from 'react-router-dom';

import { HOME_PATH } from '@routers/constants';
import useTraders from '@hooks/use-trader';

import { useAuth } from '@src/auth-wrapper';

import Layout from '@components/atoms/layout';
import { FormUpsertPricesProjection } from '@components/molecules/form/upsert-prices-projection';
import ModalComponent from '@components/atoms/modal';
import { ModalConditionsOfSignings } from '@components/molecules/upsert-prices-projection/modal-conditions-of-signings';

import { UpserPricesProjectionPageSkeelton } from './skeleton';

function UpsertPricesProjectionPage() {
  const { user, loading: loadingAuth } = useAuth();
  const { getTraderForUserHandler, traderById, loading: loadingTraders } = useTraders();

  useEffect(() => {
    if (user && !traderById) getTraderForUserHandler(user.id);
  }, [user, traderById]);

  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openConditionsOfSigningsModal, setOpenConditionsOfSigningsModal] = useState<boolean>(false);

  const [layoutTitle, setLayoutTitle] = useState<string>();

  const navigate = useNavigate();
  const handleSendNewPricesClick = () => {
    navigate(0);
  };

  const handleNavigateToHistoryClick = () => {
    navigate(HOME_PATH);
  };
  const currentURL = window.location.href;
  const label = currentURL.includes('atualizar-projecao/') ? 'Atualizar projeção' : 'Cadastrar projeção';

  const navigationHistoryLinks: LayoutProps['navigationHistoryLinks'] = [
    {
      label: 'Histórico',
      url: HOME_PATH,
    },
    {
      label: label,
      url: '#',
    },
  ];
  return (
    <Layout title={layoutTitle} navigationHistoryLinks={navigationHistoryLinks} darkerBackground>
      <div className="flex col-span-full w-full h-full">
        {loadingAuth || loadingTraders ? (
          <UpserPricesProjectionPageSkeelton />
        ) : (
          ((user && traderById) || user?.isAdmin || user?.isSuperAdmin) && (
            <FormUpsertPricesProjection
              user={user}
              onFormSuccess={() => setOpenConfirmationModal(true)}
              onFormFailure={() => setOpenErrorModal(true)}
              setLayoutTitle={setLayoutTitle}
              setOpenConditionsOfSigningsModal={setOpenConditionsOfSigningsModal}
            />
          )
        )}
        {openConfirmationModal && (
          <ModalComponent
            title="Preços Enviados"
            description="Os preços foram registrados com sucesso. Obrigado!"
            className="text-center"
            isOpen={openConfirmationModal}
            setOpen={handleNavigateToHistoryClick}
            icon={{ name: 'CheckCircleIcon', color: 'green' }}
            buttonsProps={[
              {
                label: 'Enviar outros preços',
                kind: 'secondary',
                size: '2',
                onClick: handleSendNewPricesClick,
              },
              {
                label: 'Voltar para home',
                kind: 'primary',
                size: '2',
                onClick: handleNavigateToHistoryClick,
              },
            ]}
          />
        )}
        {openErrorModal && (
          <ModalComponent
            title="Erro ao enviar preços"
            description="Verifique se todos os campos foram preenchidos corretamente e tente novamente."
            className="text-center "
            isOpen={openErrorModal}
            setOpen={() => setOpenErrorModal(false)}
            icon={{ name: 'XCircleIcon', color: 'red' }}
            buttonsProps={[
              {
                label: 'Voltar ',
                kind: 'tertiary',
                size: '2',
                onClick: () => setOpenErrorModal(false),
              },
            ]}
          />
        )}
        {openConditionsOfSigningsModal && (
          <ModalConditionsOfSignings
            isModalOpenLabel={openConditionsOfSigningsModal}
            setIsModalOpenLabel={setOpenConditionsOfSigningsModal}
          />
        )}
      </div>
    </Layout>
  );
}

export default UpsertPricesProjectionPage;
