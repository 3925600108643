import React, { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { AxiosPromise } from 'axios';

import { createPricesProjection, getPartnerPricesProjection, getPricesProjection } from '@services/calculator-api';
import { IPaginatedResponseBase } from '@contexts/types';
import { transformObjectKeysToCamelCase } from '@utils/common';

import { IPricesProjection, IPricesProjectionPayload } from './types';

export * from './types';

export type PricesProjectionContextType = {
  setPartnerId: React.Dispatch<React.SetStateAction<string | undefined>>;
  partnerId?: string;
  pricesProjections: IPaginatedResponseBase<IPricesProjection> | undefined;
  createPricesProjectionHandler: (values: IPricesProjectionPayload, projectionId?: string) => AxiosPromise;
  getPartnerPricesProjectionsHandler: (page: number) => void;
  getPricesProjectionByIdHandler: () => void;
  pricesProjectionById: IPricesProjection | undefined;
  setPricesProjectionId: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
};

export const PricesProjectionContext = createContext({} as PricesProjectionContextType);

interface Provider {
  children: React.ReactNode;
}

const PricesProjectionsProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [pricesProjections, setPricesProjections] = useState<IPaginatedResponseBase<IPricesProjection>>();
  const [pricesProjectionById, setPricesProjectionById] = useState<IPricesProjection | undefined>();
  const [pricesProjectionId, setPricesProjectionId] = useState<string>('');
  const [partnerId, setPartnerId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);

  function getPricesProjectionByIdHandler() {
    if (pricesProjectionId) {
      setLoading(true);
      getPricesProjection(pricesProjectionId)
        .then((response) => {
          const responseData = response.data;
          const camelizedResponseData = transformObjectKeysToCamelCase(responseData) as IPricesProjection;
          if (camelizedResponseData) {
            setPricesProjectionById(camelizedResponseData);
          } else setPricesProjectionById(undefined);
        })
        .catch(() => setPricesProjectionById(undefined))
        .finally(() => setLoading(false));
    }
  }

  function getPartnerPricesProjectionsHandler(page: number = 1) {
    if (partnerId) {
      setLoading(true);
      getPartnerPricesProjection(partnerId, page)
        .then((response) => {
          const responseData = response.data.data;
          const camelizedResponseData = transformObjectKeysToCamelCase(
            responseData,
          ) as IPaginatedResponseBase<IPricesProjection>;
          if (camelizedResponseData) {
            setPricesProjections(camelizedResponseData);
          } else setPricesProjections(undefined);
        })
        .catch(() => setPricesProjectionById(undefined))
        .finally(() => setLoading(false));
    }
  }

  async function createPricesProjectionHandler(values: IPricesProjectionPayload, projectionId?: string) {
    setLoading(true);
    return await createPricesProjection(values, projectionId).finally(() => setLoading(false));
  }

  useEffect(() => {
    getPricesProjectionByIdHandler();
  }, [pricesProjectionId]);

  useEffect(() => {
    getPartnerPricesProjectionsHandler();
  }, [partnerId]);

  return (
    <PricesProjectionContext.Provider
      value={{
        partnerId,
        setPartnerId,
        createPricesProjectionHandler,
        pricesProjections,
        getPartnerPricesProjectionsHandler,
        setPricesProjectionId,
        pricesProjectionById,
        getPricesProjectionByIdHandler,
        loading,
      }}
    >
      {children}
    </PricesProjectionContext.Provider>
  );
};

export default PricesProjectionsProvider;
