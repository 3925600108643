export function PriceProjectionsHistoryTableHead() {
  return (
    <thead>
      <th className="grid grid-cols-10 gap-4 place-content-center text-paragraph-medium font-bold w-full min-w-fit h-9 bg-[#E8E8E8] rounded-xl p-5">
        <td className="col-span-3 text-start">Responsável</td>
        <td className="col-span-2 text-start">Encargos</td>
        <td className="col-span-2 text-start">Data de Criação</td>
        <td className="col-span-2 text-start">Validade</td>
        <td className="col-span-1 text-start">Editar</td>
      </th>
    </thead>
  );
}
